<template>
  <v-menu
    v-model="menu"
    offset-y
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        class="mx-auto ml-2 hidden-sm-and-down"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar >
            <v-icon large>
              mdi-account-circle
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content v-if="!user">
            <v-skeleton-loader
              class="mx-auto"
              max-width="300"
              type="list-item-two-line"
            ></v-skeleton-loader>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-list-item-title>{{ user.firstname | capitalize }} {{ user.lastname | capitalize }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
              @click="menu = false"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <UserPreferences />

      <v-divider />

      <UserAccesses />

      <v-card-text v-if="currentPermissions && currentPermissions.includes('authorizations.access')">
        <ManageAccesses />
      </v-card-text>

      <v-card-actions>
        <ChangePassword v-if="!['ChangePwd'].includes($route.name)" class="mr-5" />
        <v-spacer />
        <Logout />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
    UserPreferences: () => import("@/components/User/UserPreferences"),
    UserAccesses: () => import("@/components/User/UserAccesses"),
    ManageAccesses: () => import("@/components/User/ManageAccessesBtn"),
    ChangePassword: () => import("@/components/User/ChangePasswordBtn"),
    Logout: () => import("@/components/User/Logout"),
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      userBundle: (state) => state.account.userBundle,
      targets: (state) => state.account.targets,
      currentPermissions: (state) => state.account.currentPermissions,
    }),
  },
}
</script>
